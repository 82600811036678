export const objectToFormData = (obj, formData = new FormData(), namespace = "") => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      let propName = namespace ? `${namespace}[${key}]` : key;
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        if (obj[key] instanceof File) {
          formData.append(propName, obj[key]);
        } else {
          objectToFormData(obj[key], formData, propName);
        }
      } else {
        formData.append(propName, obj[key]);
      }
    }
  }
  return formData;
};
