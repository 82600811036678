export const formatCurrency = (amount, currency = "USD", locale = "en-US") => {
  // Create a number formatter instance based on locale and currency code
  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2, // Ensure 2 decimal places
  });

  // Format the amount using the formatter
  return formatter.format(amount);
};
