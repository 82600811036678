import React, { useEffect, useRef, useState } from "react";
import { H3, H4 } from "../Shared/Text/Headers";
import { LinkIcon } from "@heroicons/react/24/outline";
import LazyImage from "../Shared/LazyImage";
import Button from "../Shared/Buttons/Button";
import SuccessIcon from "../../assets/svgs/success.svg";
import Conffetti from "../../assets/svgs/conffetti.svg";
import { apiRequest } from "src/async/apiUtils";

const SetupProgress = () => {
  return (
    <>
      <H3
        className="text-center !text-3xl font-semibold text-black"
        caption="You’re registration done, it’s all setup, but we will tell you when it’s done."
        captionClassName="text-center !text-lg">
        Registration Successful
      </H3>
      <div className="flex justify-center">
        <div className="w-32">
          <LazyImage
            src={SuccessIcon}
            className="h-full w-full object-contain"
          />
        </div>
      </div>
      <div className="mt-20 flex items-center justify-between gap-6 rounded-xl border border-gray-200 px-6 py-5 shadow-md">
        <div className="flex-shrink flex-grow space-y-2">
          <H4 className="!text-lg !font-semibold">Sit tight, we’re deploying your instance…</H4>
          <div className="pl-3">
            <p className="text-sm text-gray-400">You can close this tab if you need to.</p>
            <p className="text-sm text-gray-400">You’ll get an email as well when everything is done.</p>
          </div>
        </div>
        <div className="flex flex-shrink-0 justify-center">
          <svg
            viewBox="0 0 100 100"
            className="animated-svg">
            <g style={{ transform: "translate(-206px, -206px)" }}>
              <path
                d="M273.5,274.7h-1.1v-35.3c0-0.4-0.3-0.7-0.7-0.7l0,0h-12.6c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v2.9h-1.3v-2.9
                c0-0.4-0.3-0.7-0.7-0.7l0,0h-2.9v-2c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v2h-2.9c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v2.9h-2
                c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v5.6h-5.6c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v25.4h-1.1c-0.4,0-0.7,0.3-0.7,0.7
                c0,0.4,0.3,0.7,0.7,0.7h35.1c0.4,0,0.7-0.3,0.7-0.7C274.2,275,273.9,274.7,273.5,274.7z M249.9,240h5.8v2.2h-5.8V240z M247.7,274.7
                h-0.4v-1.1c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v1.1h-0.4v-2.2h2.2V274.7L247.7,274.7z M252.2,257.3v17.3H249v-2.9
                c0-0.4-0.3-0.7-0.7-0.7h-3.6c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0v2.9h-3.1v-24.7h11.2V257.3L252.2,257.3z M252.9,248.6h-5.6v-4.9
                h11.2v13h-4.9v-2.2h2c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-2v-2.2h2c0.4,0,0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7h-2v-0.2
                C253.5,248.9,253.2,248.6,252.9,248.6C252.9,248.6,252.9,248.6,252.9,248.6L252.9,248.6z M264.8,274.7h-11.2V258h11.2V274.7z
                M271.1,274.7h-4.9v-18.2c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.2h-1.3v-0.2c0-0.4-0.3-0.7-0.7-0.7
                c-0.4,0-0.7,0.3-0.7,0.7v0.2h-2.2V240h11.2L271.1,274.7L271.1,274.7z"
                fill="#83b0b5"
              />
              <path
                d="M256.4,273.3c0.4,0,0.7-0.3,0.7-0.7V260c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v12.6
                C255.8,273,256.1,273.3,256.4,273.3C256.4,273.3,256.4,273.3,256.4,273.3z M259.1,273.3c0.4,0,0.7-0.3,0.7-0.7V260
                c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v12.6C258.5,273,258.8,273.3,259.1,273.3C259.1,273.3,259.1,273.3,259.1,273.3z
                M261.8,273.3c0.4,0,0.7-0.3,0.7-0.7V260c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v12.6C261.2,273,261.5,273.3,261.8,273.3z
                M262.7,244.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V243c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C262.1,244.2,262.4,244.5,262.7,244.5C262.7,244.5,262.7,244.5,262.7,244.5z M265.4,244.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V243
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C264.8,244.2,265.1,244.5,265.4,244.5C265.4,244.5,265.4,244.5,265.4,244.5z
                M268.1,244.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V243c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C267.5,244.2,267.8,244.5,268.1,244.5C268.1,244.5,268.1,244.5,268.1,244.5z M262.7,249c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C262.1,248.7,262.4,249,262.7,249C262.7,249,262.7,249,262.7,249z M265.4,249
                c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C264.8,248.7,265.1,249,265.4,249
                C265.4,249,265.4,249,265.4,249z M268.1,249c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7
                v0.9C267.5,248.7,267.8,249,268.1,249C268.1,249,268.1,249,268.1,249z M262.7,253.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V252
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C262.1,253.2,262.4,253.5,262.7,253.5z M265.4,253.5c0.4,0,0.7-0.3,0.7-0.7
                c0,0,0,0,0,0V252c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C264.8,253.2,265.1,253.5,265.4,253.5z M268.1,253.5
                c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V252c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C267.5,253.2,267.8,253.5,268.1,253.5z
                M243.9,255.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C243.2,255,243.5,255.3,243.9,255.3z M246.6,255.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7
                c-0.4,0-0.7,0.3-0.7,0.7v0.9C245.9,255,246.2,255.3,246.6,255.3z M249.3,255.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C248.6,255,248.9,255.3,249.3,255.3z M243.9,259.8c0.4,0,0.7-0.3,0.7-0.7
                c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C243.2,259.5,243.5,259.8,243.9,259.8z M246.6,259.8
                c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C245.9,259.5,246.2,259.8,246.6,259.8z
                M249.3,259.8c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C248.6,259.5,248.9,259.8,249.3,259.8z M243.9,264.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7
                c-0.4,0-0.7,0.3-0.7,0.7v0.9C243.2,264,243.5,264.3,243.9,264.3z M246.6,264.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C245.9,264,246.2,264.3,246.6,264.3C246.6,264.3,246.6,264.3,246.6,264.3z
                M249.3,264.3c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C248.6,264,248.9,264.3,249.3,264.3C249.3,264.3,249.3,264.3,249.3,264.3z M243.9,268.8c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C243.2,268.5,243.5,268.8,243.9,268.8L243.9,268.8z M246.6,268.8
                c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C245.9,268.5,246.2,268.8,246.6,268.8
                C246.6,268.8,246.6,268.8,246.6,268.8L246.6,268.8z M249.3,268.8c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7
                c-0.4,0-0.7,0.3-0.7,0.7v0.9C248.6,268.5,248.9,268.8,249.3,268.8C249.3,268.8,249.3,268.8,249.3,268.8L249.3,268.8z M268.1,258
                c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C267.5,257.7,267.8,258,268.1,258z
                M268.1,262.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9
                C267.5,262.2,267.8,262.5,268.1,262.5z M268.1,267c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9c0-0.4-0.3-0.7-0.7-0.7
                c-0.4,0-0.7,0.3-0.7,0.7v0.9C267.5,266.7,267.8,267,268.1,267z M268.1,271.5c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-0.9
                c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.9C267.5,271.2,267.8,271.5,268.1,271.5z M256.2,246.6c0-0.4-0.3-0.7-0.7-0.7h-5.4
                c-0.4,0-0.7,0.3-0.7,0.7c0,0,0,0,0,0c0,0.4,0.3,0.7,0.7,0.7h5.4C255.9,247.2,256.2,246.9,256.2,246.6
                C256.2,246.6,256.2,246.6,256.2,246.6z"
                fill="#83b0b5"
              />
            </g>
            <circle
              cx="50"
              cy="50"
              r="30"
              stroke="#e7dfc6"
              strokeWidth="4"
              fill="none"></circle>
            <circle
              className="circle"
              cx="50"
              cy="50"
              r="30"
              stroke="#e25245"
              strokeLinecap="round"></circle>
            <text
              x="50%"
              y="90%"
              text-anchor="middle"
              className="progress-text">
              Setting up...
            </text>
          </svg>
        </div>
      </div>
    </>
  );
};

const Completion = ({ domain }) => {
  return (
    <>
      <H3 className="text-center !text-4xl font-semibold text-black">Congratulations !!!</H3>
      <div className="flex justify-center">
        <div className="w-32">
          <LazyImage
            src={Conffetti}
            className="h-full w-full object-contain"
          />
        </div>
      </div>
      <div className="mt-20 flex items-center justify-between gap-6 rounded-xl border border-gray-200 px-6 py-5 shadow-md">
        <div className="flex-shrink flex-grow">
          <p className="text-base text-gray-600">You’re setup is completed and you're instance is ready.</p>
          <p className="text-base text-gray-600">Follow the link to view your instance.</p>
        </div>
        <div className="flex-shrink-0">
          <Button
            version="primary"
            type="button"
            onClick={() => window.open(`https://${domain}/manage`, "_blank")}>
            <LinkIcon className="h-6 w-6" />
            <span>Link</span>
          </Button>
        </div>
      </div>
    </>
  );
};

const SetupProgressCompletion = ({ formData = null }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const intervalIdRef = useRef(null);

  const onHandleCheck = async () => {
    try {
      await apiRequest("GET", `/domains/:domain/dns/verify`, { params: { domain: formData?.domain } });
      if (intervalIdRef?.current) clearInterval(intervalIdRef.current);
      setIsCompleted(true);
    } catch (error) {}
  };

  useEffect(() => {
    if (formData?.domain) {
      intervalIdRef.current = setInterval(onHandleCheck, 10000); // Call the API every 10 sec.
      return () => {
        clearInterval(intervalIdRef.current); // Clear the interval when the component is unmounted
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.domain]);

  return <div className="space-y-12 py-8">{isCompleted ? <Completion domain={formData?.domain} /> : <SetupProgress />}</div>;
};

export default SetupProgressCompletion;
