import { ArrowUturnLeftIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import React, { useMemo, useState } from "react";
import Checkbox from "../Shared/Forms/Inputs/Checkbox";
import Input from "../Shared/Forms/Inputs/Input";
import { apiRequest } from "src/async/apiUtils";
import Button from "../Shared/Buttons/Button";
import { toast } from "react-toastify";
import { noLogo } from "src/config/host";
import { objectToFormData } from "src/helpers";

const AccountInformationForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const allowedExts = ["jpg", "jpeg", "png", "gif", "svg"];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onImageChange = (e, key) => {
    if (e.target.files[0]) {
      const ext = e.target.files[0].type.split("/")[1];

      if (!allowedExts.includes(ext)) {
        toast.error("Please upload a valid image.");
      } else {
        onHandleChange(key, e.target.files[0]);
      }
    }
  };

  const ImageHandler = ({ imageKey }) => {
    const imageSource = useMemo(() => {
      const image = formData?.[imageKey];
      return image instanceof File ? URL.createObjectURL(image) : "";
    }, [imageKey]);

    return (
      <div className={`group relative flex items-center gap-4`}>
        <div className={`flex h-16 w-16 cursor-pointer items-center justify-center overflow-hidden rounded-full border border-gray-200 bg-white`}>
          {imageSource ? (
            <img
              src={imageSource}
              alt="Logo"
              className="h-full w-full object-cover"
              onError={(e) => (e.target.src = noLogo)}
            />
          ) : (
            <UserCircleIcon className="h-8 w-8 stroke-2 text-gray-500" />
          )}
        </div>
        <div className="flex-shrink-0">
          <Button
            version="gray"
            className="relative !h-auto overflow-hidden py-3">
            <span className="text-sm font-semibold text-gray-700">Change</span>
            <input
              type="file"
              className="absolute right-0 top-0 z-10 h-full w-96 cursor-pointer opacity-0"
              onChange={(e) => onImageChange(e, imageKey)}
            />
          </Button>
        </div>
      </div>
    );
  };

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const body = {
        ...formData,
        plan: formData?.plan?._id,
      };

      await apiRequest(
        "POST",
        `/basic-data/validation`,
        {
          body: objectToFormData(body),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setView("choosePlan");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="my-4 space-y-5">
      <div className="space-y-6">
        <div className="relative">
          <Input
            inline={true}
            label={"Name"}
            wrapperClass={"bg-white"}
            placeholder={"John Doe"}
            value={formData?.name}
            onChange={(e) => onHandleChange("name", e.target.value)}
          />
        </div>
        <div className="relative">
          <Input
            inline={true}
            label={"Company"}
            wrapperClass={"bg-white"}
            placeholder={"Google Inc."}
            value={formData?.company_name}
            onChange={(e) => onHandleChange("company_name", e.target.value)}
          />
        </div>
        <div className="flex-shrink">
          <div className="mb-2 flex text-sm font-semibold uppercase leading-none text-gray-400 md:text-[10px]">Photo</div>
          <ImageHandler imageKey="image" />
        </div>
        <div className="flex-shrink">
          <div className="mb-2 flex text-sm font-semibold uppercase leading-none text-gray-400 md:text-[10px]">Company Logo</div>
          <ImageHandler imageKey="company_image" />
        </div>
        <div className="relative">
          <div className="flex items-start gap-3">
            <span className="pt-1">
              <Checkbox
                isChecked={formData?.terms_condition}
                onChange={(e) => onHandleChange("terms_condition", e.target.checked)}
              />
            </span>
            <span className="text-base text-gray-500">
              I agree with ClearSquare{" "}
              <a
                href="/"
                className="text-highlightColor hover:underline">
                product terms of service
              </a>{" "}
              and{" "}
              <a
                href="/"
                className="text-highlightColor hover:underline">
                privacy policy
              </a>
              .
            </span>
          </div>
        </div>
        <div className="relative flex items-center justify-center gap-2">
          <Button
            version="secondary"
            disabled={isSubmitting}
            onClick={() => setView("accountSetupForm")}>
            <ArrowUturnLeftIcon className="h-6 w-6 " />
          </Button>
          <Button
            version="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            className="!w-full"
            onClick={onHandleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountInformationForm;
