import { Elements, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { formatCurrency } from "src/helpers/priceHelper";
import { apiRequest } from "src/async/apiUtils";
import { loadStripe } from "@stripe/stripe-js";
import { objectToFormData } from "src/helpers";
import Button from "../Shared/Buttons/Button";
import React, { useState } from "react";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onHandleSubmit = async () => {
    try {
      setIsSubmitting(true);

      let confirmError, paymentIntent;

      if (formData?.mode === "payment_intent") {
        const response = await stripe.confirmPayment({
          elements,
          redirect: "if_required",
        });
        confirmError = response.error;
        paymentIntent = response.paymentIntent;
      } else {
        const response = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: ``,
            payment_method_data: {
              billing_details: {
                name: formData?.name,
              },
            },
          },
          redirect: "if_required",
        });
        confirmError = response.error;
        paymentIntent = response.setupIntent;
      }

      if (confirmError) {
        toast.error(confirmError.message);
        setIsSubmitting(false);
        return;
      }

      // Check if the payment succeeded
      if (paymentIntent.status !== "succeeded") {
        toast.error("Payment status unknown.");
        setIsSubmitting(false);
        return;
      }

      const { data } = await apiRequest(
        "POST",
        "/self-setup",
        {
          body: objectToFormData({ ...formData, plan: formData?.plan?._id, payment_method: paymentIntent?.payment_method, payment_id: paymentIntent?.id }),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      onHandleChange("domain", data?.data?.domain);
      setView("setupProgress");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-12">
      <div className="mt-6 space-y-4">
        <div className="relative rounded  p-2">
          <PaymentElement />
        </div>
        <div className="relative flex items-center justify-center gap-2">
          <Button
            version="secondary"
            disabled={isSubmitting}
            onClick={() => setView("choosePlan")}>
            <ArrowUturnLeftIcon className="h-6 w-6 " />
          </Button>
          <Button
            version="primary"
            className="!w-full"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={onHandleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

// Wrapping CardForm in Elements provider
const CardFormWithStripe = ({ formData, onHandleChange, setView }) => {
  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: formData?.clientSecret,
    appearance,
  };

  return (
    <>
      <div>
        <div className="flex w-full items-center gap-3 space-x-2 text-3xl font-semibold">{formData?.plan?.name || "Plan Name"}</div>
        <div className="py-1 pl-0.5 text-sm opacity-75">{formData?.plan?.description || "No description provided."}</div>
      </div>
      <div className="my-3 flex items-center space-x-3">
        {formData?.plan?.pricing?.rates?.map((price, index) => {
          const originalAmount = price?.amount || 0;
          const discountedAmount = originalAmount - (price?.discount_amount || 0);
          const discountPercentage = price?.discount_percentage || 0;
          return (
            <div
              key={price?._id}
              className="flex items-center space-x-2">
              {discountPercentage > 0 && <span className="text-xl text-gray-400 line-through">{formatCurrency(originalAmount, formData?.plan?.currency)}</span>}
              <span className="text-xl font-medium text-gray-600">
                {formatCurrency(discountedAmount, formData?.plan?.currency)}/{price?.interval}
              </span>
              {index < formData?.plan.pricing.rates.length - 1 && <span className="text-xl">|</span>}
              {discountPercentage > 0 && <span className="text-sm font-semibold text-green-600">Save {discountPercentage}%</span>}
            </div>
          );
        })}
      </div>
      <div className="space-y-2">
        {formData?.plan?.free_trial?.status && (
          <div className="rounded-md border border-blue-100 bg-blue-50 px-4 py-2">
            <span className="text-sm font-medium text-blue-600">Free Trial:</span>
            <span className="ml-2 text-lg font-semibold text-blue-800">{formData?.plan?.free_trial?.days} days</span>
            <p className="mt-1 text-sm text-blue-700">This plan includes a free trial of {formData?.plan?.free_trial?.days} days. After the trial ends, your subscription will continue automatically.</p>
          </div>
        )}
      </div>
      <Elements
        options={options}
        stripe={stripePromise}>
        <CardForm
          formData={formData}
          onHandleChange={onHandleChange}
          setView={setView}
        />
      </Elements>
    </>
  );
};

export default CardFormWithStripe;
