import Input from "../Shared/Forms/Inputs/Input";
import { apiRequest } from "src/async/apiUtils";
import Button from "../Shared/Buttons/Button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { objectToFormData } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const AccountSetupForm = ({ formData = null, onHandleChange = () => {}, setView = () => {} }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=(?:.*[a-z]){2,})(?=.*\d)(?=.*[^\w\s]).{8,}$/;
    return passwordRegex.test(password);
  };

  const onHandleSubmit = async () => {
    if (!formData?.password || !validatePassword(formData.password)) {
      toast.error("Password must be at least 8 characters long and include at least 1 uppercase letters, 2 lowercase letters, 1 number, and 1 special character.");
      return;
    }

    try {
      setIsSubmitting(true);
      await apiRequest(
        "POST",
        `/user-data/validation`,
        {
          body: objectToFormData({ ...formData, plan: formData?.plan?._id }),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setView("accountInformationForm");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="my-4 space-y-5">
      <div className="space-y-6">
        <div className="relative">
          <Input
            inline={true}
            label={"Work email"}
            wrapperClass={"bg-white"}
            placeholder={"youlooknicetoday@company.com"}
            value={formData?.email}
            onChange={(e) => onHandleChange("email", e.target.value)}
          />
        </div>
        <div className="relative">
          <Input
            inline={true}
            type="password"
            name={"password"}
            label={"Password"}
            wrapperClass={"bg-white"}
            placeholder={"At least 8 characters, with at least 1 uppercase letters, 2 lowercase letters, 1 number, and 1 special character."}
            value={formData?.password}
            onChange={(e) => onHandleChange("password", e.target.value)}
          />
        </div>
        <div className="flex flex-wrap text-sm">
          <PasswordValidationItem
            isWaiting={formData?.password === ""}
            condition={formData?.password.length >= 8}
            title={"At least 8 characters"}
          />
          <PasswordValidationItem
            isWaiting={formData?.password === ""}
            condition={(formData?.password.match(/[a-z]/g) || []).length >= 2}
            title={"Contains at least two lowercase letters"}
          />
          <PasswordValidationItem
            isWaiting={formData?.password === ""}
            condition={/[A-Z]/.test(formData?.password)}
            title={"Contains at least one uppercase letter"}
          />
          <PasswordValidationItem
            isWaiting={formData?.password === ""}
            condition={/[0-9]/.test(formData?.password)}
            title={"Contains at least one number"}
          />
          <PasswordValidationItem
            isWaiting={formData?.password === ""}
            condition={/[^\w\s]/.test(formData?.password)}
            title={"Contains at least one special character (@, #, $, %, etc.)"}
          />
        </div>
        <div className="relative">
          <Button
            version="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            className="!w-full"
            onClick={onHandleSubmit}>
            Next
          </Button>
        </div>
        <div className="relative">
          <span className="text-gray-500">Already have a paid instance?</span>
          <Button
            version="default"
            className="ml-2 !inline-block !h-auto !w-auto !font-semibold !text-highlightColor">
            Log in to your Portal
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountSetupForm;

const PasswordValidationItem = ({ isWaiting = true, condition, title }) => (
  <div className="ml-3 mt-2 flex items-center">
    {isWaiting ? <XMarkIcon className="mr-1 h-5 w-5 text-gray-400" /> : condition ? <CheckIcon className="mr-1 h-5 w-5 text-green-500" /> : <XMarkIcon className="mr-1 h-5 w-5 text-red-500" />}

    <span className={classNames("text-[14px], font-normal", isWaiting ? "text-gray-400" : condition ? "text-green-500" : "text-red-500")}>{title}</span>
  </div>
);
