import React from 'react';
import LazyImage from "../Shared/LazyImage";

const TrustedCompanies = () => {
  const companies = [
    { _id: 1, name: "Parita", logo:"https://static.wixstatic.com/media/4d9a87_4ae6186ef02f4597926775dca7d8b31e~mv2.png"},
    { _id: 2, name: "Red Bull", logo:"https://upload.wikimedia.org/wikipedia/en/thumb/f/f5/RedBullEnergyDrink.svg/1200px-RedBullEnergyDrink.svg.png"},
    { _id: 3, name: "Samsung", logo:"https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Samsung_Logo.svg/1000px-Samsung_Logo.svg.png"},
    { _id: 4, name: "Jeanz", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/image_logo-66f681dbe67df76ba00d5ee7-3?1734417245216"},
    { _id: 5, name: "Tesla", logo:"https://upload.wikimedia.org/wikipedia/commons/e/e8/Tesla_logo.png"},
    { _id: 5, name: "TRC Healthcare", logo:"https://open-domo-portals.s3.us-west-2.amazonaws.com/image_logo-6716e31a7ad990142c8b4e15-0?1734417509858"},
  ]
  return (
    <div className="space-y-6 pt-6">
      <div className="text-gray-400 font-medium text-lg">Trusted by over 50,000 companies</div>
      {companies?.length > 0 && (
        <div className="flex items-center flex-wrap gap-10">
          {companies?.map((item, index) => (
            <div className="h-8 w-auto flex" key={index}>
              <LazyImage src={item.logo} alt={item.name} classes={"object-contain w-full h-full grayscale"} effect={"blur"} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TrustedCompanies;