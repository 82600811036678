import SetupProgressCompletion from "src/components/SelfSetup/SetupProgressCompletion";
import AccountInformationForm from "src/components/SelfSetup/AccountInformationForm";
import CardFormWithStripe from "src/components/SelfSetup/CardFormWithStripe";
import AccountSetupForm from "src/components/SelfSetup/AccountSetupForm";
import TrustedCompanies from "src/components/SelfSetup/TrustedCompanies";
import ChoosePlan from "src/components/SelfSetup/ChoosePlan";
import { H3 } from "src/components/Shared/Text/Headers";
import { CheckIcon } from "@heroicons/react/24/outline";
import Logo from "src/components/Shared/Icons/Logo";
import WizardLayout from "src/layouts/WizardLayout";
import { classNames } from "src/helpers/classNames";
import { useState } from "react";

const SelfSetup = () => {
  const formJson = {
    name: "",
    email: "",
    password: "",
    company_name: "",
    domain: "",
    interval: "",
    plan: null,
  };

  const [formData, setFormData] = useState(formJson);
  const [view, setView] = useState("accountSetupForm");

  const onHandleChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const steps = [
    {
      step: "Account Setup",
      text: (
        <H3
          caption="Get everything you need to start exploring data in five minutes."
          className="!text-xl !font-semibold"
          captionClassName={"!text-gray-500"}>
          Get strated with ClearSquare
        </H3>
      ),
    },
    {
      step: "Account Information",
      text: (
        <H3
          caption="We'll only use this information to improve your experience"
          className="!text-xl !font-semibold"
          captionClassName={"!text-gray-500"}>
          Tell us a bit about yourself
        </H3>
      ),
    },
    {
      step: "Choose Plan",
      text: (
        <H3
          caption="Choose a plan here and proceed to payment."
          className="!text-xl !font-semibold"
          captionClassName={"!text-gray-500"}>
          Let's get your first instance set up, {formData?.name.split(" ")[0]}
        </H3>
      ),
    },
    {
      step: "Payment Details",
      text: (
        <H3
          caption={`Start exploring data in five minutes ${formData?.plan?.free_trial?.days > 0 ? `all with a ${formData?.plan?.free_trial?.days}-day free trial.` : ""}`}
          className="!text-xl !font-semibold"
          captionClassName={"!text-gray-500"}>
          Input your card details!
        </H3>
      ),
    },
  ];
  const stepIndex = {
    accountSetupForm: 0,
    accountInformationForm: 1,
    choosePlan: 2,
    cardForm: 3,
  };

  return (
    <>
      <WizardLayout
        leftHeaderContent={<Logo />}
        childrenContainerClass={view === "cardForm" ? "max-w-[1024px]" : ""}>
        <div>{steps[stepIndex[view]]?.text}</div>
        {view !== "setupProgress" && (
          <div className="relative z-0 mx-auto mb-8 mt-8 flex w-full items-center justify-between gap-2">
            {steps?.map((item, index) => (
              <div
                className={classNames("flex items-center gap-2", index !== steps?.length - 1 ? "flex-shrink flex-grow" : "")}
                key={index}>
                <div className={classNames("flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border text-sm font-semibold", index <= stepIndex[view] ? "border-highlightColor bg-white text-highlightColor" : "border-gray-200 bg-gray-200 text-gray-400")}>
                  {index < stepIndex[view] ? <CheckIcon className="h-5 w-5" /> : index + 1}
                </div>
                <div className={classNames("flex-shrink-0 whitespace-nowrap text-base font-semibold", index <= stepIndex[view] ? "text-gray-700" : "text-gray-400")}>{item?.step}</div>
                {index !== steps?.length - 1 && <div className={classNames("mt-px flex-shrink flex-grow border-t-2", index <= stepIndex[view] - 1 ? "border-highlightColor" : "border-gray-300")} />}
              </div>
            ))}
          </div>
        )}

        <div className="my-3">
          {view === "accountSetupForm" && (
            <AccountSetupForm
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "accountInformationForm" && (
            <AccountInformationForm
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "choosePlan" && (
            <ChoosePlan
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "cardForm" && (
            <CardFormWithStripe
              formData={formData}
              onHandleChange={onHandleChange}
              setView={setView}
            />
          )}
          {view === "setupProgress" && <SetupProgressCompletion formData={formData} />}
        </div>
        <TrustedCompanies />
      </WizardLayout>
    </>
  );
};

export default SelfSetup;
